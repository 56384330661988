import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Inventory from './components/pages/inventory';
import Home from './components/pages/home';
import CarDetails from './components/pages/carDetails';
import './App.css'
import Footer from './components/Footer'
import AboutUS from './components/pages/AboutUs'
import Contact from './components/pages/Contact'
import RequestCar from './components/pages/RequestCar'
import SellCar from './components/pages/SellCar'

function App(){
  return(
    <div className="App">
      <Router>
        <Navbar />
        <div className="content-container">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path="/inventory" element={<Inventory/>} />
            <Route path="/car/:id" element={<CarDetails/>} />
            <Route path="/about" element={<AboutUS />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/requestCar" element={<RequestCar />} />
            <Route path="/sellCar" element={<SellCar />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;