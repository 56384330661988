import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaGripLinesVertical } from "react-icons/fa6";
import carfax from '../images/carfax.jpg'
import './inventory.css';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const Inventory = () => {
    const location = useLocation();
    const query = useQuery();
    const filterString = query.get('filters'); 
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');
    const [sortOption, setSortOption] = useState('');
    const [filters, setFilters] = useState({
        brand: '',
        model: '',
        year: ''
    });
    const [filterOptions, setFilterOptions] = useState({
        brands: [],
        models: [],
        years: []
    });
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    const API_URL = process.env.REACT_APP_API_URL;
    useEffect(() => {
        if (filterString) {
            const filters = parseFilterString(filterString);
            setFilters(filters);  
            fetchFilteredProducts(filters);  
        } else {
            fetchProducts();  
            fetchFilterOptions();  
        }
        window.scrollTo(0, 0);  
    }, [filterString]);

    useEffect(() => {
        if (filters.brand || filters.model || filters.year) {
            fetchFilteredProducts(filters);  
        }
    }, [filters]);
    const parseFilterString = (filterString) => {
        const filters = {};
        const filterPairs = filterString.split(';');
        
        filterPairs.forEach(pair => {
            const [key, value] = pair.split('=');
            if (key && value) {  
                filters[key] = value;
            }
        });
    
        return filters;
    };
    const fetchFilteredProducts = async (filters) => {
        try {
            const response = await axios.get(`${API_URL}/cars/`, { params: filters });
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching filtered car data!', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${API_URL}/cars/`);
            const productsData = response.data;
            setProducts(productsData);
        } catch (error) {
            console.error('There was an error fetching the car data!', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (option) => {
        setSortOption(option);
        let sortedProducts = [...products];

        switch (option) {
            case 'year-asc':
                sortedProducts.sort((a, b) => a.year - b.year);
                break;
            case 'year-desc':
                sortedProducts.sort((a, b) => b.year - a.year);
                break;
            case 'brand-asc':
                sortedProducts.sort((a, b) => a.brand.localeCompare(b.brand));
                break;
            case 'brand-desc':
                sortedProducts.sort((a, b) => b.brand.localeCompare(a.brand));
                break;
            case 'price-asc':
                sortedProducts.sort((a, b) => a.price - b.price);
                break;
            case 'price-desc':
                sortedProducts.sort((a, b) => b.price - a.price);
                break;
            default:
                break;
        }

        setProducts(sortedProducts);
    };
    const fetchFilterOptions = async () => {
        try {
            const response = await axios.get(`${API_URL}/cars/filter_options/`);
            setFilterOptions(response.data);
        } catch (error) {
            console.error('Error fetching filter options!', error);
        }
    };

    const handleFilterChange = (e) => { 
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };
    function isVIN(input) {
        const vinLength = 17;
        const vinRegex = /^[A-HJ-NPR-Z0-9]{17}$/;
    
        // Check if the input length is 17 characters and matches the VIN pattern
        return input.length === vinLength && vinRegex.test(input.toUpperCase());
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();  // Trigger search when "Enter" key is pressed
        }
    };
    const handleSearch = () => {
        const params = {};

        if (search) {
            if (isVIN(search)) {
                params.vin = search;  
            } else {
                params.search = search; 
            }
        }
        if (filters.year) {
            params.year = filters.year;
        }
        if (filters.brand) {
            params.brand = filters.brand;
        }
        if (filters.model) {
            params.model = filters.model;
        }
        console.log('Search parameters:', params);
        axios.get(`${API_URL}/cars/`, { params })
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the filtered data!', error);
            });
    };

    const handleCardClick = (id) => {
        navigate(`/car/${id}`);
    }; 
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//labels-prod.s3.amazonaws.com/vin_select/214719.js";
        script.defer = true;
    
        script.onload = () => {
            if (products.length > 0 && window.MonroneyLabels) {
                console.log("Initializing MonroneyLabels after script load and products fetched");
                window.MonroneyLabels.init();
            }
        };
    
        script.onerror = () => console.error("Failed to load MonroneyLabels script");
        document.body.appendChild(script);
    
        return () => {
            document.body.removeChild(script);
        };
    }, [loading]);
    
    
    
    useEffect(() => {
        if (window.MonroneyLabels) {
            console.log("Reinitializing MonroneyLabels on Inventory page");
            window.MonroneyLabels.init();
        }
    }, [location, filters, sortOption]);
    
    return (
        <div>
            <div className='inventory-title-container'>
                <div className='inventory-title-header'>
                    Inventory
                </div>
            </div>
            <div className="inventory-container">
                <div className='inventory-title'>
                    <div className="inventory-info">
                        <span>VEHICLES FOUND: {products.length}</span>
                        <div className="sort-dropdown-container">
                            <select value={sortOption} onChange={(e) => handleSort(e.target.value)} className="sort-dropdown">
                                <option value="" disabled>Set Sort By</option>
                                <option value="year-asc">Year: Oldest to Newest</option>
                                <option value="year-desc">Year: Newest to Oldest</option>
                                <option value="brand-asc">Brand: A-Z</option>
                                <option value="brand-desc">Brand: Z-A</option>
                                <option value="price-asc">Price: Low to High</option>
                                <option value="price-desc">Price: High to Low</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="filter-section py-4">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg my-1">
                                    <input 
                                        type="text" 
                                        placeholder="Search" 
                                        value={search} 
                                        onChange={(e) => setSearch(e.target.value)} 
                                        onKeyPress={handleKeyPress}
                                        className="search-bar"
                                    />
                                </div>
                                <div className="col-lg my-1">
                                    <select name="year" value={filters.year} onChange={handleFilterChange} className="year-dropdown">
                                        <option value="">Select Year</option>
                                        {filterOptions.years.map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg my-1">
                                    <select name="brand" value={filters.brand} onChange={handleFilterChange} className="brand-dropdown">
                                        <option value="">Select Brand</option>
                                        {filterOptions.brands.map(brand => (
                                            <option key={brand} value={brand}>{brand}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg my-1">
                                    <select name="model" value={filters.model} onChange={handleFilterChange} className="model-dropdown">
                                        <option value="">Select Model</option>
                                        {filterOptions.models.map(model => (
                                            <option key={model} value={model}>{model}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-lg my-1">
                                    <button 
                                        className="primary-btn px-4 w-100 search-button" 
                                        type="button" 
                                        onClick={handleSearch}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-grid">
                    <div className="grid">
                        {loading && <div>Loading...</div>}
                        {!loading && products.length === 0 && <div>No products available</div>}
                        {!loading && products.length > 0 && products.map((product) => (
                            <div className="productCard" 
                            key={product.id}
                            onClick={() => handleCardClick(product.id)}
                            style={{ cursor: 'pointer' }}
                            >
                                <img className="Car-Profile-Pic" src={product.profile_pic} alt={`${product.brand} ${product.model}`} />
                                <div className="card-body">
                                    <div className='icon-container'>
                                        <FaGripLinesVertical className="icon-style"/>
                                        <h5 className="card-title">{product.year} {product.brand} {product.model}</h5>
                                        
                                    </div>
                                    <p className="card-price">${product.price}</p>
                                    <div className='card-links'>
                                        <span className="carfax-link">
                                            <a 
                                                href={`http://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${product.vin}`}
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                            <img 
                                                src={carfax}
                                                width="200" 
                                                height="80" 
                                                border="0" 
                                                alt="Show me the Carfax"
                                            />
                                            </a>
                                        </span>
                                        <span className="monroney-labels" data-year={product.year} data-make={product.brand} data-vin={product.vin} data-vendor-id="cmmcars" onClick={(e) => e.stopPropagation()}></span>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Inventory;
